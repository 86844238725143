.header-container{
	background-color: rgba(17, 161, 3, 1);
    height: 50px;
    padding: 5px;
    color: white;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
}

.one{
	width: 35%;
	min-width: 190px;
	display: flex;
    align-items: center;
}
.three{
	max-width: 165px;
	display: flex;
    justify-content: center;
    align-items: center;
}

.logo{
	width: 100%;
	max-width: 250px
}